import { combineReducers } from 'redux'
import accountsReducer from './accounts'
import resourcesReducer from './resources'
import wmlResourcesReducer from './wmlResources'
import profileReducer from './profile'
import collectionReducer from './collection'
import editorReducer from './editor'
import autoLabelReducer from './autoLabel'
import authReducer from './auth'
import trainingRunsReducer from './trainingRuns'
import projectsReducer from './projects'
import modelAppsReducer from './modelApps'
import showcasesReducer from './showcases'
import tagsReducer from './tags'
import settingsReducer from './settings'

export default combineReducers({
  accounts: accountsReducer,
  resources: resourcesReducer,
  wmlResources: wmlResourcesReducer,
  profile: profileReducer,
  projects: projectsReducer,
  collection: collectionReducer,
  editor: editorReducer,
  autoLabel: autoLabelReducer,
  auth: authReducer,
  runs: trainingRunsReducer,
  apps: modelAppsReducer,
  showcases: showcasesReducer,
  tags: tagsReducer,
  settings: settingsReducer,
})
