// Actions
const SET_AUTHENTICATED = 'cloud-annotations/auth/SET_AUTHENTICATED'
const SET_ATTEMPTED_PAGE = 'cloud-annotations/auth/SET_ATTEMPTED_PAGE'

// Reducer
const defaultAuth = {
  authenticated: false,
  attemptedPage: '/projects',
}

export default function reducer(accounts = defaultAuth, action = {}) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return { ...accounts, authenticated: action.authenticated }
    case SET_ATTEMPTED_PAGE:
      return { ...accounts, attemptedPage: action.attemptedPage }
    default:
      return accounts
  }
}

// Action Creators
export const setAuthenticationStatus = a => ({ type: SET_AUTHENTICATED, authenticated: a })
export const setAttemptedPage = a => ({ type: SET_ATTEMPTED_PAGE, attemptedPage: a })
