// Actions
const SET = 'cloud-annotations/profile/SET'

const ADMIN_EMAILS = [
  'aije.e@ibm.com',
  'joseph.santarcangelo@ibm.com',
  'yluo@ca.ibm.com',
  'sandipsahajoy@ibm.com',
  'bs@ibm.com',
  'maduvan.kasi@ibm.com',
  'yasmine.hemmati@ibm.com',
  'Kathy.An@ibm.com',
  'James.Tang@ibm.com'
];

const profileWithAdmin = (profile) => {
  return {
    ...profile,
    admin: ADMIN_EMAILS.includes(profile.email)
  };
}

// Reducer
export default function reducer(profile = {}, action = {}) {
  switch (action.type) {
    case SET:
      return profileWithAdmin(action.profile)
    default:
      return profileWithAdmin(profile)
  }
}

// Action Creators
export const setProfile = p => ({ type: SET, profile: p })
