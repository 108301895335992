import TrainingRuns from 'api/trainingRuns'
import { formatDate } from 'Utils'

// Actions
const SET = 'cloud-annotations/trainingRuns/SET'

// Reducer
export default function reducer(runs = [], action = {}) {
  switch (action.type) {
    case SET:
      return action.runs
    default:
      return runs
  }
}

// Action Creators
export const setTrainingRuns = (b) => ({ type: SET, runs: b })

// Side Effects
export const loadTrainingRuns = async (_projectId) => {
  if (!_projectId) return
  const runsApi = new TrainingRuns()
  const runs = await runsApi.listTrainingRuns(_projectId)

  let training_runs = runs.map((run) => {
    const {
      id,
      name,
      projectId,
      tool,
      algorithm,
      enhancements,
      parameters,
      instructions,
      tags,
      model,
      lastStarted,
      lastCompleted,
      accuracy,
      createdAt,
      trainingId,
      modelId,
      status
    } = run

    let length
    if (lastStarted && lastCompleted) {
      const startDate = new Date(lastStarted)
      const endDate = new Date(lastCompleted)
      length = Math.floor((endDate - startDate) / 1000)
    }

    const formattedDate = formatDate(new Date(createdAt))

    return {
      id,
      name,
      projectId,
      tool,
      algorithm,
      enhancements,
      parameters,
      instructions,
      tags,
      model,
      lastStarted:
        lastStarted === undefined
          ? undefined
          : new Date(lastStarted).toLocaleDateString(),
      lastCompleted:
        lastCompleted === undefined
          ? undefined
          : new Date(lastCompleted).toLocaleDateString(),
      length,
      accuracy,
      created: formattedDate,
      trainingId,
      modelId,
      status
    }
  })

  return setTrainingRuns(training_runs)
}
