import _ from 'lodash';
import COS from 'api/COSv2'
import { defaultEndpoint } from 'endpoints'

// Bucket names must be globally unique and DNS-compliant; names between 3 and 63 characters long must be made of lowercase letters, numbers, and dashes.
// Bucket names must begin and end with a lowercase letter or number.
// Bucket names resembling IP addresses are not allowed.
const generateBucketName = (name) => {
  return _.kebabCase(name).slice(0,30) + '-' + _.kebabCase(new Date().toISOString());
}

const createBucket = async (name, cosInstanceId) => {
  let attempt = 0;

  while (attempt < 3) {
    attempt += 1;
    let bucketname = generateBucketName(name);

    try {
      let bucket = await new COS({
        endpoint: defaultEndpoint,
      }).createBucket({
        Bucket: bucketname,
        IBMServiceInstanceId: cosInstanceId,
      })

      return { bucket, bucketname };
    } catch (error) {
      if (attempt > 2) {
        throw error;
      }
      console.log(`Failed to create bucket ${bucketname}. Trying again....`);
    }
  }
}

export default class Projects {
  /**
   * Creates a new project.
   *
   * @param {string} name
   * @param {string} type
   * @param {string} cloudAccountId
   * @param {string} cosInstanceId
   * @param {string} templateId
   */
  createProject = async ({ name, type, cloudAccountId, cosInstanceId, bucketname, templateId }) => {
    if (bucketname === undefined) {
      ({ bucketname } = await createBucket(name, cosInstanceId));
    }

    const url = `/api/projects`;
    const data = {
      name,
      type,
      cloudAccountId,
      cosInstanceId,
      bucketname,
      templateId,
      // bucketLocation
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    let res = await fetch(url, options)

    const result = await res.json();

    if (res.status < 200 || res.status > 399) {
      console.error('Error creating project')

      if ([11000, 11001].includes(result.code) && result.keyPattern.name === 1) {
        throw new Error(`Project name already in use`);
      } else if (result.message) {
        throw new Error(result.message);
      }

      throw new Error(`Error: ${result.name} [${result.code}]`);
    }

    return result;
  }

  /**
   * Returns a list of all projects owned by the authenticated sender of the
   * request.
   */
  listProjects = async () => {
    const url = `/api/projects`
    const options = {
      method: 'GET',
    }

    let res = await fetch(url, options)
    return res.json();
  }

  /**
   * Returns a project by name
   *
   * @param {string} name
   */
  getProjectByName = async (name) => {
    const url = `/api/projects?name=${name}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    let res = await fetch(url, options);
    let projects = await res.json();

    return projects[0];
  }

  /**
   * Get a project by id
   *
   * @param {string} projectId
   */
  getProjectById = async (projectId) => {
    const url = `/api/projects/${projectId}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    let res = await fetch(url, options);
    let project = await res.json();

    return project;
  }

  /**
   * Get projects by template id
   *
   * @param {string} projectId
   */
   getProjectsByTemplateId = async (templateId) => {
    const url = `/api/projects?templateId=${templateId}`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const res = await fetch(url, options);
    const projects = await res.json();

    return projects;
  }

  /**
   * Deletes a project by id
   *
   * @param {string} projectId
   */
  deleteProjectById = async (projectId) => {
    const url = `/api/projects/${projectId}`;
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    let res = await fetch(url, options);
    let project = await res.json();

    return project;
  }


  /**
   * Refreshes a project's applications
   * 
   * @param {string} projectId 
   * @returns 
   */
  refreshProjectById = async (projectId) => {
    const url = `/api/projects/${projectId}/refresh`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    let res = await fetch(url, options);
    let project = await res.json();

    return project;
  }

}
