export default class ModelApps {
  /**
   * Creates a new model app.
   *
   * @param {string} projectId
   * @param {string} name
   * @param {string} tool
   * @param {object} model
   * @param {string} url
   * @param {[any]} parameters
   */
  createModelApp = async ({
    projectId,
    name,
    tool,
    model,
    url = '',
    status = 'Pending',
    parameters = {},
    instructions,
    publicModel
  }) => {
    const _url = `/api/projects/${projectId}/apps`
    const data = {
      projectId,
      name,
      tool,
      model,
      url,
      status,
      parameters,
      instructions,
      publicModel,
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    let res = await fetch(_url, options)

    const result = await res.json()

    if (res.status < 200 || res.status > 399) {
      console.error('Error creating app')
      console.error(result)
      throw new Error(`Error: ${result.name} [${result.code}]`)
    }

    return result
  }


  /**
   * Creates a new model app from instructions.
   *
   * @param {string} projectId
   * @param {string} name
   * @param {string} instructionId
   * @param {any}    model
   */
   createModelAppWithInstructions = async ({
    projectId,
    name,
    instructionId,
    tool,
    model,
    publicModel,
    starting,
    appId
  }) => {
    const url = `/api/projects/${projectId}/apps`
    const data = {
      name,
      instructionId,
      tool,
      model,
      publicModel,
      starting,
      appId
    }
    // codeeng123
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }
    console.log(data)
    let res = await fetch(url, options)

    const result = await res.json()

    if (res.status < 200 || res.status > 399) {
      console.error('Error creating model app')
      console.error(result)
    }

    return result
  }

  /**
   * Returns a list of all apps owned by the authenticated sender of the
   * request.
   *
   * @param {string} projectId
   */
  listModelApps = async (projectId) => {
    const url = `/api/projects/${projectId}/apps`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
    let res = await fetch(url, options)
    return res.json()
  }

  /**
   * Get a training run by id
   *
   * @param {string} projectId
   * @param {string} appId
   */
  getModelAppById = async (projectId, appId) => {
    const url = `/api/projects/${projectId}/apps/${appId}`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    let app = await res.json()

    return app
  }

  /**
   * Deletes a training run by id
   *
   * @param {string} projectId
   * @param {string} appId
   */
  deleteModelAppById = async (projectId, appId) => {
    const url = `/api/projects/${projectId}/apps/${appId}`
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    let app = await res.json()

    return app
  }

   /**
   * Stop model by id
   *
   * @param {string} projectId
   * @param {string} appId
   * * @param {string} appName
   */
    stopModelById = async (projectId, appId) => {
      const url = `/api/projects/${projectId}/apps/stopModel`
      const data = {appId: appId}
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      }
      let res = await fetch(url, options)
      let app = await res.json()
      
      return app
    }



  /**
   * Delete all apps that match use the training run with id runId
   *
   * @param {string} projectId
   * @param {string} runId
   */
   deleteAppsWithTrainingRunId = async (projectId, runId) => {
    const url = `/api/projects/${projectId}/apps/run/${runId}`
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    let app = await res.json()

    return app
  }
}
