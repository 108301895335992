export default class Showcases {
  /**
   * Creates a new showcase.
   */
  createShowcase = async ({
    projectId,
    projectType,
    showcaseType,
    name,
    description = '',
    author = '',
    appId,
    appTags = [],
    thumbnailUrl,
    url,
    visibility,
    appCreatedAt,
  }) => {
    const _url = `/api/projects/${projectId}/showcases`
    const data = {
      projectId,
      projectType,
      showcaseType,
      name,
      description,
      author,
      appId,
      appTags,
      thumbnailUrl,
      url,
      visibility,
      appCreatedAt,
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    let res = await fetch(_url, options)

    const result = await res.json()

    if (res.status < 200 || res.status > 399) {
      console.error('Error creating showcase')
      console.error(result)
      throw new Error(`Error: ${result.name} [${result.code}]`)
    }

    return result
  }

  /**
   * Updates an existing showcase.
   *
   * @param {string} scId
   * @param {object} showcase
   */
  updateShowcase = async (projectId, scId, updates) => {
    const _url = `/api/projects/${projectId}/showcases/${scId}`

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updates),
    }

    let res = await fetch(_url, options)

    const result = await res.json()

    if (res.status < 200 || res.status > 399) {
      console.error('Error updating showcase')
      console.error(result)
      throw new Error(`Error: ${result.name} [${result.code}]`)
    }

    return result
  }

  /**
   * Returns a list of all showcases that are public in cv studio
   */
  listPublicShowcases = async () => {
    const url = `/api/showcases`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    return res.json()
  }

  /**
   * Returns a list of all showcases owned by the authenticated sender of the
   * request.
   *
   * @param {string} projectId
   */
  listShowcases = async (projectId) => {
    const url = `/api/projects/${projectId}/showcases`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    return res.json()
  }

  /**
   * Get a showcase by id
   *
   * @param {string} projectId
   * @param {string} scId
   */
  getShowcaseById = async (projectId, scId) => {
    const url = `/api/projects/${projectId}/showcases/${scId}`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    let app = await res.json()

    return app
  }

  /**
   * Get a showcase by appid
   *
   * @param {string} projectId
   * @param {string} appId
   */
  getShowcaseByAppId = async (projectId, appId) => {
    const url = `/api/projects/${projectId}/showcases/app/${appId}`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    let app = await res.json()

    return app
  }

  /**
   * Deletes a showcase by id
   *
   * @param {string} projectId
   * @param {string} scId
   */
  deleteShowcaseById = async (projectId, scId) => {
    const url = `/api/projects/${projectId}/showcases/${scId}`
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    let app = await res.json()

    return app
  }

  /**
   * Delete showcases that use the model app with id appId
   *
   * @param {string} projectId
   * @param {string} appId
   */
  deleteShowcasesWithAppId = async (projectId, appId) => {
    const url = `/api/projects/${projectId}/showcases/app/${appId}`
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    let app = await res.json()

    return app
  }
}
