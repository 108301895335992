export default class TagApi {
  /**
   * Creates a new Tag.
   *
   * @param {string} name
   * @param {string} kind
   * @param {string} description
   */
  createTag = async ({ name, kind, description }) => {
    const _url = `/api/tags`
    const data = {
      name,
      kind,
      description,
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    let res = await fetch(_url, options)

    const result = await res.json()

    if (res.status < 200 || res.status > 399) {
      console.error('Error creating tag')
      console.error(result)
      throw new Error(`Error: ${result.name} [${result.code}]`)
    }

    return result
  }

  /**
   * Returns a list of all tags
   */
  listTags = async () => {
    const url = `/api/tags`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    return res.json()
  }

  /**
   * Returns a list of all tags with this kind
   *
   * @param {string} kind
   */
  listTagsByKind = async (kind) => {
    const url = `/api/tags/kind/${kind}`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    return res.json()
  }

  /**
   * Get a tag by name
   *
   * @param {string} name
   */
  getTag = async (name) => {
    const url = `/api/tags/name/${name}`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    let app = await res.json()

    return app
  }

  /**
   * Deletes a tag by name
   *
   * @param {string} name
   */
  deleteTag = async (name) => {
    const url = `/api/tags/name/${name}`
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    let app = await res.json()

    return app
  }
}
