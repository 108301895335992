// Actions
const RETRIEVE_SETTINGS = 'cloud-annotations/settings/RETRIEVE_SETTINGS'
const ENABLE_SCALING = 'cloud-annotations/settings/ENABLE_SCALING'
const SET_MAX_IMAGE_WIDTH = 'cloud-annotations/settings/SET_MAX_IMAGE_WIDTH'
const SET_MAX_IMAGE_HEIGHT = 'cloud-annotations/settings/SET_MAX_IMAGE_HEIGHT'
const PRESERVE_ASPECT_RATIO = 'cloud-annotations/settings/PRESERVE_ASPECT_RATIO'

// Reducer
const defaultSettings = {
  scalingEnabled: true,
  maxImageWidth: 1500,
  maxImageHeight: 1500,
  aspectRatioPreserved: true
}

export default function reducer(settings = defaultSettings, action = {}) {
  switch (action.type) {
    case RETRIEVE_SETTINGS:
      if (`${action.project}Settings` in localStorage) {
        return JSON.parse(localStorage.getItem(`${action.project}Settings`))
      }
      return settings
    case ENABLE_SCALING:
      settings = { ...settings, scalingEnabled: action.scaling }
      localStorage.setItem(`${action.project}Settings`, JSON.stringify(settings))
      return settings
    case SET_MAX_IMAGE_WIDTH:
      if (!action.hasOwnProperty('width') || action.width.isNaN()) {
        action.width = defaultSettings.maxImageWidth;
      }
      settings = { ...settings, maxImageWidth: action.width }
      localStorage.setItem(`${action.project}Settings`, JSON.stringify(settings))
      return settings
    case SET_MAX_IMAGE_HEIGHT:
      if (!action.hasOwnProperty('height') || action.height.isNaN()) {
        action.height = defaultSettings.maxImageHeight;
      }
      settings = { ...settings, maxImageHeight: action.height }
      localStorage.setItem(`${action.project}Settings`, JSON.stringify(settings))
      return settings
    case PRESERVE_ASPECT_RATIO:
      settings = { ...settings, aspectRatioPreserved: action.aspectRatio }
      localStorage.setItem(`${action.project}Settings`, JSON.stringify(settings))
      return settings
    default:
      return settings
  }
}

// Action Creators
export const retrieveSettings = (project) => ({
  type: RETRIEVE_SETTINGS,
  project: project
})

export const enableScaling = (project, scaling) => ({
  type: ENABLE_SCALING,
  project: project,
  scaling: scaling
})

export const setMaxImageWidth = (project, width) => ({
  type: SET_MAX_IMAGE_WIDTH,
  project: project,
  width: width
})

export const setMaxImageHeight = (project, height) => ({
  type: SET_MAX_IMAGE_HEIGHT,
  project: project,
  height: height
})

export const preserveAspectRatio = (project, aspectRatio) => ({
  type: PRESERVE_ASPECT_RATIO,
  project: project,
  aspectRatio: aspectRatio
})