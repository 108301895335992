import Projects from 'api/projects'
import { formatDate } from 'Utils'

// Actions
const SET = 'cloud-annotations/projects/SET'
const SET_ACTIVE_PROJECT = 'cloud-annotations/projects/SET_ACTIVE_PROJECT'

const defaultProjects = {
  projects: [],
  activeProject: null,
}

// Reducer
export default function reducer(projects = defaultProjects, action = {}) {
  switch (action.type) {
    case SET:
      return { ...projects, projects: action.projects }
    case SET_ACTIVE_PROJECT:
      return { ...projects, activeProject: action.activeProject }
    default:
      return projects
  }
}

// Action Creators
export const setProjects = (b) => ({ type: SET, projects: b })
export const setActiveProject = (b) => ({
  type: SET_ACTIVE_PROJECT,
  activeProject: b,
})

// Side Effects
export const loadProjects = async () => {
  const projectsApi = new Projects()
  let projects = await projectsApi.listProjects()

  let _projects = projects.map((project) => {
    const {
      id,
      name,
      type,
      bucketname,
      cloudAccountId,
      cosInstanceId,
      createdAt,
      updatedAt,
    } = project

    const formattedDate = formatDate(new Date(createdAt))

    return {
      id,
      name,
      type,
      bucketname,
      cloudAccountId,
      cosInstanceId,
      updatedAt: new Date(updatedAt).toLocaleDateString(),
      location: project.bucketLocation || 'us',
      created: formattedDate,
    }
  })

  return setProjects(_projects)
}
