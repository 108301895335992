import COS from './COSv2'

export default class TrainingRuns {
  /**
   * Creates a new training run.
   *
   * @param {string} projectId
   * @param {string} name
   * @param {string} tool
   * @param {string} algorithm
   * @param {[string]} enhancements
   * @param {[any]} parameters
   * @param {[string]} tags
   */
  createTrainingRun = async ({
    projectId,
    name,
    tool,
    algorithm,
    enhancements,
    model,
    parameters,
    tags,
  }) => {
    const url = `/api/projects/${projectId}/runs`
    const data = {
      name,
      tool,
      algorithm,
      enhancements,
      model,
      parameters,
      tags,
    }

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    let res = await fetch(url, options)

    const result = await res.json()

    if (res.status < 200 || res.status > 399) {
      console.error('Error creating training run')
      console.error(result)
      throw new Error(`Error: ${result.name} [${result.code}]`)
    }

    return result
  }


  /**
   * Creates a new training run from instructions.
   *
   * @param {string} projectId
   * @param {string} name
   * @param {string} instructionId
   * @param {string} tool
   */
   createTrainingRunWithInstructions = async ({
    projectId,
    name,
    instructionId,
    tool,
    parameters,
    modelDefId
  }) => {
    const url = `/api/projects/${projectId}/runs`
    const data = {
      name,
      instructionId,
      tool,
      modelId: modelDefId,
      parameters
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    let res = await fetch(url, options)

    const result = await res.json()
    if (res.status < 200 || res.status > 399) {
      console.error('Error creating training run')
      console.error(result)
      throw new Error(`Error: ${result.name} [${result.code}]`)
    }
    return result
  }

  /**
   * Create new Watson training job
   * request.
   *
   * @param {string} projectId
   * @param {string} trainingRunId
   */
  createNewWatsonTrainingRun = async(projectId, trainingRunId, parameters, modelDefId) => {
    // const url = `/api/projects/${projectId}/runs/${modelId}/status`
    const url = `/api/projects/${projectId}/runs/${trainingRunId}/create`
    const data = {
      type: "pytorch",
      parameters: parameters,
      modelDefId
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }

    let res = await fetch(url, options)
    const model = await res.json()

    const modelId = model.metadata.id
    
  
      const url1 = `/api/projects/${projectId}/runs/${trainingRunId}/update`
     
      const data1 = {
        watsonId: modelId,
        status: res.status > 300 ? "failed" : "pending"
      }
      const options1 = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data1)
      }
      let res2 = await fetch(url1, options1)
      const update = await res2.json()

      return model
  }

  /**
   * Returns Watson ML model training log
   * request.
   *
   * @param {string} location
   * @param {string} bucket
   * @param {string} path
   */
  getTrainingLog = async(location, bucket, path) => {
    const filePath = location + "/training-log.txt"
    const endpointPath = path.split("//")

    const value = await new COS({endpoint: endpointPath[1]}).getObject({
      Bucket: bucket,
      Key: filePath,
    })
    return value;
  }

  /**
   * Returns Watson ML model training status
   * request.
   *
   * @param {string} projectId
   * @param {string} modelId
   */
  getWatsonTrainStatus = async (projectId, modelId) => {
    // /api/projects/${projectId}/runs
    const url = `/api/projects/${projectId}/runs/${modelId}/status`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    // const result = await res.json()
    // console.log(result)
    return res.json()
  }

  /**
   * Returns a list of all training runs owned by the authenticated sender of the
   * request.
   *
   * @param {string} projectId
   */
  listTrainingRuns = async (projectId) => {
    const url = `/api/projects/${projectId}/runs`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    // const result = await res.json()
    // console.log(result)
    return res.json()
  }

  /**
   * Get a training run by id
   *
   * @param {string} projectId
   * @param {string} trainingRunId
   */
  getTrainingRunById = async (projectId, trainingRunId) => {
    const url = `/api/projects/${projectId}/runs/${trainingRunId}`
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    let trainingRun = await res.json()

    return trainingRun
  }

  /**
   * Deletes a training run by id
   *
   * @param {string} projectId
   * @param {string} trainingRunId
   */
  deleteTrainingRunById = async (projectId, trainingRunId) => {
    const url = `/api/projects/${projectId}/runs/${trainingRunId}`
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    let res = await fetch(url, options)
    let trainingRun = await res.json()

    return trainingRun
  }
}