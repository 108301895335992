import Showcases from 'api/showcases'
import { formatDate } from 'Utils'

// Actions
const SET = 'cloud-annotations/showcases/SET'

// Reducer
export default function reducer(showcases = [], action = {}) {
  switch (action.type) {
    case SET:
      return action.showcases
    default:
      return showcases
  }
}

// Action Creators
export const setShowcases = (b) => ({ type: SET, showcases: b })

// Side Effects
export const loadShowcases = async (_projectId) => {
  if (!_projectId) return
  const showcasesApi = new Showcases()
  const _showcases = await showcasesApi.listShowcases(_projectId) || []

  let showcases = _showcases.map((showcase) => {
    const {
      id,
      projectId,
      projectType,
      showcaseType,
      name,
      description,
      author,
      appId,
      appTags,
      thumbnailUrl,
      url,
      visibility,
      appCreatedAt,
      createdAt,
    } = showcase

    const formattedDate = formatDate(new Date(createdAt))

    return {
      id,
      projectId,
      projectType,
      showcaseType,
      name,
      description,
      author,
      appId,
      appTags,
      thumbnailUrl,
      url,
      visibility,
      appCreatedAt,
      created: formattedDate,
    }
  })

  return setShowcases(showcases)
}
