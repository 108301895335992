import ModelApps from 'api/modelApps'
import moment from 'moment'
import { formatDate } from 'Utils'

// Actions
const SET = 'cloud-annotations/modelApps/SET'

// Reducer
export default function reducer(apps = [], action = {}) {
  switch (action.type) {
    case SET:
      return action.apps
    default:
      return apps
  }
}

// Action Creators
export const setModelApps = (b) => ({ type: SET, apps: b })

// Side Effects
export const loadModelApps = async (_projectId) => {
  if (!_projectId) return
  const appsApi = new ModelApps()
  const apps = await appsApi.listModelApps(_projectId)
  let modelApps = apps.map((app) => {
    const {
      id,
      name,
      projectId,
      tool,
      model,
      url,
      instructions,
      tags,
      parameters,
      status,
      createdAt,
      expiresAt,
      publicModel
    } = app
    const formattedDate = formatDate(new Date(createdAt))
    const expiryStr = expiresAt
      ? moment().to(moment(new Date(expiresAt)))
      : null
    const formattedExpiryOrNull = expiresAt
      ? formatDate(new Date(expiresAt))
      : null

    return {
      id,
      name,
      projectId,
      tool,
      model,
      url,
      status,
      instructions,
      tags,
      parameters,
      created: formattedDate,
      expiry: formattedExpiryOrNull,
      expiryStr,
      publicModel
    }
  })

  return setModelApps(modelApps)
}
