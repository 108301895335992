import TagApi from 'api/tag'

// Actions
const SET = 'cloud-annotations/tags/SET'

// Reducer
export default function reducer(tags = [], action = {}) {
  switch (action.type) {
    case SET:
      return action.tags
    default:
      return tags
  }
}

// Action Creators
export const setTags = (b) => ({ type: SET, tags: b })

// Side Effects
export const loadTags = async () => {
  const tagsApi = new TagApi()
  const tags = await tagsApi.listTags()

  let Tags = tags.map((tag) => {
    const { id, name, kind, description } = tag

    return {
      id,
      name,
      kind,
      description,
    }
  })

  return setTags(Tags)
}
